/* eslint-disable no-underscore-dangle */
/* eslint-disable no-fallthrough */
/* eslint-disable react/button-has-type */
import { lazy, Suspense, useEffect, useRef } from 'react';
import {
  Outlet,
  ReactLocation,
  Router,
  useLocation,
  useNavigate,
} from '@tanstack/react-location';
import { Provider } from 'react-redux';
import Cookies from 'js-cookie';
import {
  QueryClient,
  QueryClientProvider,
  useQueryClient,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { useIdleTimer } from 'react-idle-timer';
import routes from './pages/routes';
import { store } from './store';
import { useAppDispatch, useAppSelector } from './hooks/redux';

import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import {
  doesUserTokenExist,
  getUserIdFromToken,
  GOOGLE_API_KEY,
  LocalStorageKeys,
  MessageOptions,
  toast,
  TOAST_DEFAULT_DURATION,
  TOAST_WARNING_DURATION,
} from './utils';
import ErrorFallback from './pages/ErrorFallback';
import socket from './services/socketService';
import { useGetLogout, useGetUserInfo } from './queries';
import {
  setMissionFeedbackModalMission,
  toggleIsMissionFeedbackModalVisible,
} from './store/missionFeedbackModalSlice';
import {
  setRequestMissionModalMission,
  toggleIsRequestMissionModalVisible,
  toggleIsConfirmRequestMissionModalVisible,
  setConfirmRequestMissionModalMission,
} from './store/requestMissionSlice';
import { addNewGeoJSON } from './store/socketGeoJSONSlice';
import { toggleIsVersionModalVisible } from './store/versionModalSlice';
import {
  setPrelaunchDrone,
  setPrelaunchMission,
  toggleIsLaunchCheckModalVisible,
} from './store/prelaunchModalsSlice';
import { useCloseAllUtil } from './utils/closeAllUtil';
import { setIsInPiPMode, setIsInPiPModeThermal } from './store/livePageSlice';
import { toggleIsTwoFAEnabled } from './store/twoFAModalSlice';

const Notifications = lazy(() => import('./components/Notifications'));
const Navbar = lazy(() => import('./components/Navbar'));
const NotificationDropdown = lazy(
  () => import('./components/NotificationDropdown')
);
const DeleteMissionModal = lazy(
  () => import('./components/DeleteMissionModal')
);
const WeatherModal = lazy(() => import('./components/WeatherModal'));
const EmergencyControls = lazy(() => import('./components/EmergencyControls'));
const ChatModal = lazy(() => import('./components/TeamChat'));
const VersionModal = lazy(() => import('./components/VersionModal'));
const MissionFeedbackModal = lazy(
  () => import('./components/MissionFeedbackModal')
);
const MissionSummaryModal = lazy(
  () => import('./components/MissionSummaryModal')
);
const PrelaunchModals = lazy(() => import('./components/PreLaunchModals'));

const AcceptRequestMissionModal = lazy(
  () => import('./components/AcceptRequestMissionModal')
);

const AcceptConfirmRequestMissionModal = lazy(
  () => import('./components/AcceptConfirmRequestMissionModal')
);

const AddNewMissionModal = lazy(
  () => import('./components/AddNewMissionModal')
);

function App() {
  const birdsEyeSocketSetup = useRef(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: userData } = useGetUserInfo();
  const logoutMutation = useGetLogout();
  const userId = getUserIdFromToken();
  const queryClient = useQueryClient();
  // code for making sure mission request is not overwritten when there are multiple requests
  const confirmRequestedMission = useAppSelector(
    (state) => state.requestMissionModal.isConfirmModalVisible
  );
  const isAcceptRequestedMissionModalOpen = useAppSelector(
    (state) => state.requestMissionModal.isModalVisible
  );

  const acceptRequestedMissionModal = useRef(isAcceptRequestedMissionModalOpen);
  const confirmRequestedMissionModal = useRef(confirmRequestedMission);
  // keeps track of current state of the two modals wether they are open or not
  useEffect(() => {
    acceptRequestedMissionModal.current = isAcceptRequestedMissionModalOpen;
    confirmRequestedMissionModal.current = confirmRequestedMission;
  }, [confirmRequestedMission, isAcceptRequestedMissionModalOpen]);

  const { start: startTimer, pause: pauseTimer } = useIdleTimer({
    onIdle: () => {
      if (
        !userId ||
        !userData?.settings?.inActivity?.enabled ||
        location.current.pathname.includes('live') ||
        location.current.pathname.includes('location')
      )
        return;
      logoutMutation.mutate(undefined, {
        onSuccess: () => {
          Cookies.remove('chocolateChip');
          localStorage.removeItem(LocalStorageKeys.USER_TOKEN);
          navigate({
            to: '/login',
            replace: true,
          });
        },
      });
    },
    timeout: userData?.settings?.inActivity?.timeout,
    throttle: 500,
    startManually: true,
    startOnMount: false,
  });

  // if user decides to login with sso, we set cookie from here
  useEffect(() => {
    const pathname = window?.location?.href;
    const hasSamlAndToken =
      pathname && pathname.includes('saml') && pathname.includes('token');
    if (hasSamlAndToken) {
      const parsePathName = window?.location?.href;
      const keyWord = new URL(parsePathName);
      // Get the token parameter value
      const tokenValue = keyWord.searchParams.get('token');
      Cookies.set('chocolateChip', tokenValue, {
        expires: 8 / 24,
        secure: true,
      });

      navigate({
        to: '/missions',
        replace: true,
      });
    }
  }, [navigate]);

  // useEffect to manage inactivity timer
  useEffect(() => {
    if (userId && userData?.settings?.inActivity?.enabled) {
      startTimer();
    } else {
      pauseTimer();
    }
  }, [pauseTimer, startTimer, userData, userId]);

  // useEffect to manage socket connection
  useEffect(() => {
    if (userId) {
      socket.connect();
    } else {
      socket.disconnect();
    }
  }, [userId]);

  // useEffect to emit heartbeat and join/leave chatroom
  useEffect(() => {
    socket.on('connect', () => {
      if (!userId) return;
      socket.emit('join_chatroom', { user_id: userId });
      socket.emit('setup heartbeat', {
        email: userData?.email,
        user_id: userId,
      });
    });
    socket.on('disconnect', () => {
      if (!userId) return;
      socket.emit('leave_chatroom', { user_id: userId });
    });
  }, [userData?.email, userId]);

  // useEffect to display birdseye_alerts
  useEffect(() => {
    // if no loggedin user, return
    if (!userId) return;

    // if socket already setup, return
    if (birdsEyeSocketSetup.current) return;
    birdsEyeSocketSetup.current = true;

    socket.on('connect', () => {
      socket.on(
        'birdseye_alerts',
        (data: {
          missionComplete?: boolean;
          missionHistory?: any;
          alert_type: 'warning' | 'error' | 'simple' | 'success';
          raidAlert?: boolean;
          sent_by?: string;
          timeout?: number;
          message?: string;
        }) => {
          const toastDuration = TOAST_DEFAULT_DURATION;
          const toastWarningDuration = TOAST_WARNING_DURATION;
          // for mission complete
          if (data?.missionComplete) {
            dispatch(setIsInPiPMode(false));
            dispatch(setIsInPiPModeThermal(false));
            dispatch(toggleIsMissionFeedbackModalVisible(true));
            dispatch(setMissionFeedbackModalMission(data?.missionHistory));
            toast(`${data?.sent_by}: ${data?.message}`, {
              id: `${data?.sent_by}: ${data?.message}`,
              duration: toastDuration,
              position: 'bottom-right',
              type: MessageOptions.SUCCESS,
            });
            return;
          }
          // only for live mission page
          if (window?.location?.pathname.includes('live')) {
            // get current drone for missionId
            const missionId = window?.location?.pathname?.split('/')?.[2];
            const activeDronesList: any = queryClient.getQueryData([
              'activeDrones',
            ]);
            // match mission id and drone launch id to find an active drone name
            const activeDroneQuery = activeDronesList?.find(
              (drone: any) => drone?.launch_id?.mission?._id === missionId
            );
            // check if nickname exists
            const isCurrentDroneNOTActive =
              (activeDroneQuery?.nickname
                ? activeDroneQuery?.nickname
                : activeDroneQuery?.name) !== data?.sent_by;

            // find if alert is sent by a drone
            const dronesList: any = queryClient.getQueryData(['drones']);

            const isSentByADrone =
              typeof dronesList?.find((drone) =>
                drone?.nickname && drone?.nickname !== ''
                  ? drone?.nickname === data?.sent_by
                  : drone?.name === data?.sent_by
              ) !== 'undefined';

            // if message is sent by a drone but not the active drone, return
            if (isCurrentDroneNOTActive && isSentByADrone) return;
          }
          queryClient.invalidateQueries(['notifications']);
          // for alerts
          switch (data?.alert_type) {
            case 'error':
              toast(`${data?.sent_by}: ${data?.message}`, {
                id: `${data?.sent_by}: ${data?.message}`,
                duration: Infinity,
                position: 'bottom-right',
                style: {
                  backgroundColor: '#dc2626',
                },
                type: MessageOptions.ERROR,
                icon: 'X',
              });
              break;
            case 'warning':
              toast(`${data?.sent_by}: ${data?.message}`, {
                id: `${data?.sent_by}: ${data?.message}`,
                duration: toastWarningDuration,
                position: 'bottom-right',
                style: {
                  backgroundColor: '#f59e0b',
                },
                type: MessageOptions.WARNING,
                icon: '!!',
              });
              break;
            case 'success':
              toast(`${data?.sent_by}: ${data?.message}`, {
                id: `${data?.sent_by}: ${data?.message}`,
                duration: toastDuration,
                position: 'bottom-right',
                style: {
                  backgroundColor: '#16a34a',
                },
                type: MessageOptions.SUCCESS,
              });
              break;
            default:
              toast(`${data?.sent_by}: ${data?.message}`, {
                id: `${data?.sent_by}: ${data?.message}`,
                duration: toastDuration,
                position: 'bottom-right',
              });
              break;
          }
        }
      );
      // for user commands
      socket.on(
        'birdseye_user_command',
        (data: {
          command?: string;
          commandName?: string;
          message?: string;
          mission_id?: string;
          sent_by?: string;
          user?: string;
          user_id?: string;
        }) => {
          if (userId !== data?.user_id) {
            toast(`${data?.sent_by}: ${data?.message}`, {
              id: `${data?.sent_by}: ${data?.message}`,
              duration: 10000,
              position: 'bottom-right',
            });
          }
        }
      );
    });
    socket.on('user_prompts', (msg) => {
      // if user not logged in
      if (!userId) return;
      // for cases where accept is clicked by a user so then it should close the popup for everone else
      if (msg?.accepted_by !== '') {
        dispatch(toggleIsRequestMissionModalVisible(false));
        dispatch(setRequestMissionModalMission(null));
      }

      // show popup for a requested mission
      if (
        msg?.topic === 'SAQ' &&
        msg?.queuedMission?.state === 'NO_RESOURCES'
      ) {
        // if any of the popup modals are already open then to return and not update anything else
        if (acceptRequestedMissionModal.current === true) {
          return;
        }
        // do not show this popup on live mission page, missions page, or login page
        // not show popup if someone has already clicked accept
        dispatch(setRequestMissionModalMission(msg));
        if (
          window?.location?.pathname.includes('live') ||
          window?.location?.pathname === '/' ||
          window?.location?.pathname.includes(
            'missions' || msg?.accepted_by !== ''
          )
        ) {
          return;
        }
        // console.log('FIRST');
        // console.log(msg)
        dispatch(toggleIsRequestMissionModalVisible(true));
      }

      // show this popup if no drone was selected when requesting a mission
      if (
        msg?.topic === 'SAQ' &&
        msg?.queuedMission?.state === 'READY' &&
        msg?.queuedMission?.droneRequested !== msg?.drone?._id
      ) {
        // if any of the popup modals are already open then to return and not update anything else
        if (confirmRequestedMissionModal.current === true) {
          return;
        }
        if (
          window?.location?.pathname.includes('live') ||
          window?.location?.pathname === '/'
        ) {
          return;
        }
        // console.log('SECOND');
        dispatch(toggleIsRequestMissionModalVisible(false));
        dispatch(setRequestMissionModalMission(null));
        dispatch(toggleIsConfirmRequestMissionModalVisible(true));
        dispatch(setConfirmRequestMissionModalMission(msg));
      }

      if (msg?.msg === 'SHOW_PRETAKEOFF_MODAL') {
        dispatch(setPrelaunchDrone(msg?.drone));
        dispatch(setPrelaunchMission(msg?.mission));
        dispatch(toggleIsRequestMissionModalVisible(false));
        dispatch(setRequestMissionModalMission(null));
        dispatch(toggleIsConfirmRequestMissionModalVisible(false));
        dispatch(setConfirmRequestMissionModalMission(null));
        setTimeout(() => {
          dispatch(toggleIsLaunchCheckModalVisible(true));
        }, 700);
      }
    });

    socket.on('geojson', (msg) => {
      // only show for live page
      if (window?.location?.pathname.includes('live')) {
        const geojsonData = msg.geojson;
        // eslint-disable-next-line no-restricted-syntax
        for (const i in geojsonData.features) {
          if (!geojsonData?.features[i]?.properties?.expirationTimestamp) {
            geojsonData.features[i].properties.expirationTimestamp =
              new Date().getTime() + 10;
          }
        }
        if (msg.geojson.features[0].properties.id) {
          dispatch(addNewGeoJSON(geojsonData));
        }
      }
    });
    socket.on('logout', () => {
      logoutMutation.mutate(undefined, {
        onSuccess: () => {
          Cookies.remove('chocolateChip');
          localStorage.removeItem(LocalStorageKeys.USER_TOKEN);
          dispatch(toggleIsTwoFAEnabled(false));
          navigate({
            to: '/login',
            replace: true,
          });
        },
      });
    });
  }, [
    confirmRequestedMission,
    dispatch,
    isAcceptRequestedMissionModalOpen,
    logoutMutation,
    navigate,
    queryClient,
    userId,
  ]);

  useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .addEventListener('change', (event) => {
        // dispatch(toggleIsDarkMode(event.matches));
      });
  }, [dispatch]);

  // set zendesk widget position and hide on mount
  useEffect(() => {
    window?.zE(() => {
      window?.zE?.hide();
    });
    window.zESettings = {
      webWidget: {
        position: { horizontal: 'left', vertical: 'bottom' },
      },
    };
  }, []);

  useEffect(() => {
    if (
      !doesUserTokenExist() &&
      !location.current.pathname.includes('/recover-password') &&
      !location.current.pathname.includes('/login')
    ) {
      const redirect =
        window.location.pathname !== '/'
          ? `?redirect=${window.location.pathname}`
          : '';
      navigate({
        to: `/login${redirect}`,
        replace: true,
      });
    }
  }, [navigate, location.current.pathname, location]);

  const handleVersionModalClick = () => {
    dispatch(toggleIsVersionModalVisible(true));
  };
  const pathCheck =
    !location.current.pathname.includes('/recover-password') &&
    !location.current.pathname.includes('/login');

  useCloseAllUtil();

  return (
    <div className="">
      <div className="flex flex-col dark:text-white">
        {pathCheck && (
          <div className="fixed right-0 top-0 z-50 -mt-2 mr-12 pr-2 xl:mr-2">
            <button
              type="button"
              onClick={handleVersionModalClick}
              className="text-[10px] text-white"
            >
              Version - {import.meta.env.PACKAGE_VERSION}
            </button>
          </div>
        )}
        <Suspense fallback={null}>
          <Notifications />
        </Suspense>
        <Suspense fallback={null}>
          <Navbar />
        </Suspense>
        <div className="h-full px-4 py-8">
          <Suspense fallback={null}>
            <NotificationDropdown />
          </Suspense>
          <Suspense fallback={null}>
            <DeleteMissionModal />
          </Suspense>
          <Suspense fallback={null}>
            <WeatherModal />
          </Suspense>
          <Suspense fallback={null}>
            <EmergencyControls />
          </Suspense>
          <Suspense fallback={null}>
            <ChatModal />
          </Suspense>
          <Suspense fallback={null}>
            <VersionModal />
          </Suspense>
          <Suspense fallback={null}>
            <MissionFeedbackModal />
          </Suspense>
          <Suspense fallback={null}>
            <MissionSummaryModal />
          </Suspense>
          <Suspense fallback={null}>
            <PrelaunchModals />
          </Suspense>
          <Suspense fallback={null}>
            <AcceptRequestMissionModal />
          </Suspense>
          <Suspense>
            <AcceptConfirmRequestMissionModal />
          </Suspense>
          <Suspense fallback={null}>
            <AddNewMissionModal />
          </Suspense>
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
}

const wrappedApp = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
      },
    },
  });

  const reactLocation = new ReactLocation();
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Router location={reactLocation} routes={routes}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <Helmet>
              <script type="text/javascript">
                {`function googleMapsCallback() {} window.googleMapsCallback = googleMapsCallback;`}
              </script>
              <script
                src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&callback=googleMapsCallback&v=3.exp`}
                async
                defer
              />
              <script>
                {`(function (d, s, c) {
                var j = d.createElement(s),
                  t = d.getElementsByTagName(s)[0];
                j.src =
                  'https://unpkg.com/@elastic/apm-rum@5.12.0/dist/bundles/elastic-apm-rum.umd.min.js';
                j.onload = function () {
                  elasticApm.init(c);
                };
                t.parentNode.insertBefore(j, t);
              })(document, 'script', {
                serviceName: 'mission-manager-v5',
                serverUrl:
                  'https://2e04855a2691471794bcb9bd3162035d.apm.us-east-1.aws.found.io:443',
                  environment: '${import.meta.env.MODE}',
              });`}
              </script>
            </Helmet>
            <App />
          </Provider>
          <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
      </Router>
    </ErrorBoundary>
  );
};

export default wrappedApp;
